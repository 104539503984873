import { PRODUCT_GROUPS, STOCK_STATUS } from '@/constants/lineItem'
import { PRODUCTION_WEEK, PRODUCTION_MONTH } from '@/i18n/constants.js'

const inProductionKey = Object.keys(STOCK_STATUS).find(key => STOCK_STATUS[key] === 'in_production')

export function parse (price) {
  return (price && price.constuctor !== Number) ? parseFloat(price) : price
}

export function isBike (item) { return item.product_group === PRODUCT_GROUPS.bike }

export function hasDiscountValid (item) { return item.hasDiscount && item.discountPercentage > 0 }

export function getDiscountIcons (item) {
  if (item.discountIcon === false) return []
  const classes = ['discount-icon', 'discount-icon--rounded']
  let discountType = ''
  if (item.discountIcon === 'simple') {
    discountType = 'simple'
  }
  if (item.discountIcon === 'tier') {
    discountType = 'tier'
  }
  if (item.discountIcon === 'cumulatedTier') {
    discountType = 'tier'
  }

  return [{ class: classes, hasDiscount: 1, discountType }]
}

export function getPrice (item, isUseSimulatedPrice = false) {
  if (isUseSimulatedPrice) {
    return parse(item.simulatedPrice?.price_unit ?? 0)
  }
  if (isBike(item) || hasDiscountValid(item)) {
    return parse(item.rrp)
  }

  return parse(item.displayRRP)
}

export function getBatteryPrice (item, isUseSimulatedPrice = false) {
  if (!isBike(item)) {
    return -1
  }
  if (isUseSimulatedPrice) {
    return parse(item.simulatedPrice?.battery_unit ?? 0)
  }
  const batteryInfo = item.battery_info?.find((info) => info.material_number === item.batterySku)
  if (!batteryInfo || !batteryInfo.rrp) {
    // eslint-disable-next-line no-console
    console.warn('Battery price is missing')
  }
  return parse(batteryInfo?.rrp ?? 0)
}

export function getCombinedPrice (item, isUseSimulatedPrice = false) {
  if (!isBike(item)) {
    return -1
  }
  if (isUseSimulatedPrice) {
    return parse(item.simulatedPrice?.combined_unit ?? 0)
  }

  return parse(item.combined_rrp ?? 0)
}

export function getProductAttributes (item) {
  return [
    {
      title: '',
      value: item?.color
    },
    {
      title: item?.frame_size_label,
      value: item?.frame_size
    },
    {
      title: '',
      value: item?.gender_frame
    }
  ]
}

export function getStockStatusId (item) { return STOCK_STATUS[item.stock_status] ?? '' }

export function getStockStatusLabel (item) {
  if (item.stock_status === inProductionKey && item?.production_week) {
    return { text: PRODUCTION_WEEK, indication: item.production_week }
  }
  if (item.stock_status === inProductionKey && item?.production_month) {
    return { text: PRODUCTION_MONTH, indication: item.production_month }
  }
  return item?.stock_status_label ?? ''
}

export function getUrl (item) { return `/catalog_product/redirect/byProductSku/sku/${item.sku}` }
