<template>
  <Teleport to="body">
    <div id="cartman-success"
      data-testid="cartman-success-page"
      class="cartman-success cartman-success--modal cart-page cart-page--modal bg-primary page-centered" :class="{
        collapse: cartStore.isSuccessModalOpen === false,
        collapsed: cartStore.isSuccessModalOpen === true
      }"
    >
      <div class="page-wrapper">
        <div class="container">
          <header class="page-section__header">
            <h1 class="cartman-success__title page-section__title text--light">{{ $t(title) }}</h1>
          </header>
          <article class="page-section__body text--light">
            <div class="cartman-success__panel" v-if="message">
              <p>{{ $t(message) }}</p>
            </div>
            <div class="cartman-success__panel" v-if="runningStatusUpdate">
              <p class="text--light">
                {{ $t(ORDER_NUMBERS_WAITING) }}
                <span class="cartman-success__waiting"></span>
              </p>
            </div>
            <div class="cartman-success__panel" v-if="orderNumbers.length">
              <span class="order-success-msg__order-number-label title-4 text--light">
                  {{ $t(ORDER_NUMBERS) }}:
              </span>
              <a
                v-for="orderNumber in orderNumbers"
                class="order-success-msg__order-number-value ml-1 text--light"
                :href="orderNumber.link"
              >{{ orderNumber.orderNumber }}</a>
            </div>
          </article>
          <footer class="cartman-success__footer page-section__footer mt-5">
            <a
              data-testid="cartman-success-page--close"
              class="cartman-success__action--close btn btn--portal btn--white"
              href="/"
            >{{ $t(SUCCESS_CLOSE) }}</a>
            <a
                v-show="cartStore.totalQuantity > 0 && orderNumbers.length > 0"
                data-testid="cartman-success-page--cartlink"
                class="cartman-success__action--close btn btn--portal btn--white"
                href="#"
                @click="gotoCart"
            >{{ $t(TO_CART)}} </a>
          </footer>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script setup>
import { inject, watch, ref } from 'vue'
import {
  STATUS_OPEN,
  STATUS_SUBMITTED,
  STATUS_FAILED,
  STATUS_ERRONEUS,
  STATUS_PROCESSING,
  STATUS_CONFIRMED,
  ORDER_NUMBERS,
  ORDER_NUMBERS_WAITING,
  TITLE_NONE,
  TITLE_SUBMITTED,
  TITLE_SUBMITTED_MESSAGE,
  TITLE_PROCESSING,
  TITLE_PROCESSING_MESSAGE,
  TITLE_CONFIRMED,
  TITLE_CONFIRMED_MESSAGE,
  TITLE_FAILED,
  TITLE_FAILED_MESSAGE,
  TITLE_ERRONEUS,
  TITLE_ERRONEUS_MESSAGE,
  TITLE_TOO_LONG,
  TITLE_TOO_LONG_MESSAGE,
  SUCCESS_CLOSE,
  TO_CART
} from '@/i18n/constants'

let timeout = null
const timeoutValue = process.env.VUE_APP_SUBMITSCREEN_TIMEOUT_SEC ?? 2 * 60
const settings = inject('settings')
const cartStore = inject('cartStore', { totalQuantity: 0 })
const titleTranslation = {
  NONE: TITLE_NONE,
  SUBMITTED: TITLE_SUBMITTED,
  PROCESSING: TITLE_PROCESSING,
  CONFIRMED: TITLE_CONFIRMED,
  FAILED: TITLE_FAILED,
  ERROR: TITLE_ERRONEUS,
  TOO_LONG: TITLE_TOO_LONG
}
const messageTranslation = {
  SUBMITTED: TITLE_SUBMITTED_MESSAGE,
  PROCESSING: TITLE_PROCESSING_MESSAGE,
  CONFIRMED: TITLE_CONFIRMED_MESSAGE,
  FAILED: TITLE_FAILED_MESSAGE,
  ERROR: TITLE_ERRONEUS_MESSAGE,
  TOO_LONG: TITLE_TOO_LONG_MESSAGE
}
const statusTranslation = {
  OPEN: STATUS_OPEN,
  SUBMITTED: STATUS_SUBMITTED,
  PROCESSING: STATUS_PROCESSING,
  CONFIRMED: STATUS_CONFIRMED,
  FAILED: STATUS_FAILED,
  ERROR: STATUS_ERRONEUS
}

const props = defineProps({
  submitData: Object
})

const title = ref(TITLE_NONE)
const status = ref()
const message = ref('')
const orderNumbers = ref([])
const runningStatusUpdate = ref(true)

function pollingTimeoutAction () {
  runningStatusUpdate.value = false
  timeout = null
}

function stopPolling () {
  if (timeout) clearTimeout(timeout)
  pollingTimeoutAction()
}

function updatePage ({
  title: newTitle,
  status: newStatus,
  message: newMessage,
  orderNumbers: newOrderNumbers
}) {
  title.value = newTitle
  status.value = newStatus
  message.value = newMessage
  if (newOrderNumbers) orderNumbers.value = newOrderNumbers
}

watch(() => props.submitData, async () => {
  if (!props.submitData.location) {
    stopPolling()
    return
  }
  runningStatusUpdate.value = true
  updatePage({
    title: titleTranslation.SUBMITTED,
    message: messageTranslation.SUBMITTED,
    status: statusTranslation.SUBMITTED
  })
  timeout = setTimeout(pollingTimeoutAction, timeoutValue * 1000)
  const update = await updateStatus()
  updatePage(update)
  stopPolling()
})

function createOrderHistoryUrl (orderId) {
  const url = new URL(`/order_history/orderlist/index/?search=${orderId}`, window.location)
  return url.href
}

function sleep (seconds) {
  return new Promise(resolve => setTimeout(resolve, seconds * 1000))
}

async function updateStatus () {
  if (runningStatusUpdate.value === false) {
    return {
      title: titleTranslation.TOO_LONG,
      status: statusTranslation.PROCESSING,
      message: messageTranslation.TOO_LONG
    }
  }

  const result = await cartStore.actions.orderStatus(props.submitData.location)

  if (!result) {
    return {
      title: titleTranslation.ERROR,
      status: statusTranslation.ERROR,
      message: result?.message ?? messageTranslation.ERROR
    }
  }

  const newTitle = titleTranslation[result.cartStatus.toUpperCase()]
  const newStatus = statusTranslation[result.cartStatus.toUpperCase()]
  const newMessage = result?.message ?? messageTranslation[result.cartStatus.toUpperCase()]

  const newOrderNumbers = result.orderNumbers?.map(num => {
    const oid = parseInt(num, 10)
    return {
      link: createOrderHistoryUrl(oid),
      orderNumber: oid
    }
  }) ?? []

  if (result.retry === 0) {
    // we're done
    return {
      title: newTitle,
      status: newStatus,
      message: newMessage,
      orderNumbers: newOrderNumbers
    }
  }

  // Intermediate update
  updatePage({
    title: newTitle,
    status: newStatus,
    message: newMessage,
    orderNumbers: newOrderNumbers
  })
  await sleep(result.retry)
  return updateStatus()
}

function gotoCart () {
  if (settings.cartURL) {
    window.location.href = settings.cartURL
    return
  }
  cartStore.toggleCartModal(true)
}

</script>
